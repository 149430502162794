/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const InputBaseSizeSmallMinHeight = '32px'
export const InputBaseSizeMediumMinHeight = '40px'
export const InputBaseSizeLargeMinHeight = '48px'
export const InputBaseSpacingCommonText = '2 4px'
export const InputBaseSpacingCommonLabel = '0 4px'
export const InputBaseSpacingCommonHelper = '0 4px'
export const InputBaseSpacingSmallPadding = '4px'
export const InputBaseSpacingSmallGap = '0px'
export const InputBaseSpacingMediumPadding = '8px'
export const InputBaseSpacingMediumGap = '4px'
export const InputBaseSpacingLargePadding = '12px'
export const InputBaseSpacingLargeGap = '8px'
export const InputBaseBorderRadiusLarge = '6px'
export const InputBaseBorderRadiusMedium = '5px'
export const InputBaseBorderRadiusSmall = '4px'
export const InputBaseTypographyLabelFontFamily = 'Inter'
export const InputBaseTypographyLabelFontWeight = 400
export const InputBaseTypographyLabelLineHeight = '16px'
export const InputBaseTypographyLabelFontSize = '12px'
export const InputBaseTypographyLabelLetterSpacing = '0.4px'
export const InputBaseTypographyLabelParagraphSpacing = '0px'
export const InputBaseTypographyLabelTextDecoration = ' '
export const InputBaseTypographyLabelTextCase = ' '
export const InputBaseTypographyHelperFontFamily = 'Inter'
export const InputBaseTypographyHelperFontWeight = 400
export const InputBaseTypographyHelperLineHeight = '16px'
export const InputBaseTypographyHelperFontSize = '12px'
export const InputBaseTypographyHelperLetterSpacing = '0.4px'
export const InputBaseTypographyHelperParagraphSpacing = '0px'
export const InputBaseTypographyHelperTextDecoration = ' '
export const InputBaseTypographyHelperTextCase = ' '
export const InputBaseTypographyTextFontFamily = 'Inter'
export const InputBaseTypographyTextFontWeight = 400
export const InputBaseTypographyTextLineHeight = '20px'
export const InputBaseTypographyTextFontSize = '14px'
export const InputBaseTypographyTextLetterSpacing = '0.25px'
export const InputBaseTypographyTextParagraphSpacing = '0px'
export const InputBaseTypographyTextTextDecoration = ' '
export const InputBaseTypographyTextTextCase = ' '
