import { Box, BoxProps, ButtonBase, styled } from '@mui/material'
import { youShallNotPass } from 'lib/helpers/youShallNotPass/youShallNotPass'
import { styledWithSelector } from 'lib/helpers/styledWithSelector/styledWithSelector'
import * as tokens from '../../tokens/tokens'
import type { TButtonProps } from './Button'
import * as tkn from './ButtonBase.tokens'
import * as tkn_p from './ButtonPrimary.tokens'
import * as tkn_s from './ButtonSecondary.tokens'
import * as tkn_t from './ButtonTertiary.tokens'

type TBaseButtonProps = Pick<
  TButtonProps,
  'variant' | 'kind' | 'size' | 'startIcon' | 'endIcon' | 'fullWidth' | 'active'
>

export const buttonBase = {
  textAlign: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  fontSize: tokens.ThemeTypographyDesktopButtonFontSize,
  lineHeight: tokens.ThemeTypographyDesktopButtonLineHeight,
  fontWeight: tokens.ThemeTypographyDesktopButtonFontWeight,
  letterSpacing: tokens.ThemeTypographyDesktopButtonLetterSpacing,
  fontFamily: tokens.ThemeTypographyDesktopButtonFontFamily,
  textIndent: tokens.ThemeTypographyDesktopButtonParagraphSpacing,
  whiteSpace: 'nowrap',
} as const
const primaryNeutralActive = {
  backgroundColor: tkn_p.ButtonPrimaryColorPositivePressedBackground,
  color: tkn_p.ButtonPrimaryColorPositivePressedOnBackground,
  borderWidth: tkn_p.ButtonPrimaryBorderWidthPressed,
  borderColor: `${tkn_p.ButtonPrimaryColorPositivePressedOutline} !important`,
  zIndex: 2,
} as const

export const primaryNeutral = ({ active = false } = {}) =>
  ({
    backgroundColor: tkn_p.ButtonPrimaryColorPositiveDefaultBackground,
    color: tkn_p.ButtonPrimaryColorPositiveDefaultOnBackground,
    borderWidth: tkn_p.ButtonPrimaryBorderWidthDefault,
    borderStyle: 'solid',
    borderColor: tkn_p.ButtonPrimaryColorPositiveDefaultOutline,
    ...(active && primaryNeutralActive),
    ...(!active && {
      '&:active': primaryNeutralActive,
      '&:hover': {
        backgroundColor: tkn_p.ButtonPrimaryColorPositiveHoveredBackground,
        color: tkn_p.ButtonPrimaryColorPositiveHoveredOnBackground,
        borderWidth: tkn_p.ButtonPrimaryBorderWidthHovered,
        borderColor: tkn_p.ButtonPrimaryColorPositiveHoveredOutline,
      },
    }),
    '&:focus-visible': {
      backgroundColor: tkn_p.ButtonPrimaryColorPositiveHoveredBackground,
      color: tkn_p.ButtonPrimaryColorPositiveHoveredOnBackground,
      borderWidth: tkn_p.ButtonPrimaryBorderWidthHovered,
      borderColor: tkn_p.ButtonPrimaryColorPositiveHoveredOutline,
    },
    '&:disabled': {
      backgroundColor: tkn_p.ButtonPrimaryColorPositiveDisabledBackground,
      color: tkn_p.ButtonPrimaryColorPositiveDisabledOnBackground,
      borderWidth: tkn_p.ButtonPrimaryBorderWidthDisabled,
      borderColor: tkn_p.ButtonPrimaryColorPositiveDisabledOutline,
    },
  }) as const
const secondaryNeutralActive = {
  backgroundColor: tkn_s.ButtonSecondaryColorPositivePressedBackground,
  color: tkn_s.ButtonSecondaryColorPositivePressedOnBackground,
  borderWidth: tkn_s.ButtonSecondaryBorderWidthPressed,
  borderColor: `${tkn_s.ButtonSecondaryColorPositivePressedOutline} !important`,
  zIndex: 2,
} as const
const tertiaryNeutralActive = {
  backgroundColor: tkn_t.ButtonTertiaryColorPositivePressedBackground,
  color: tkn_t.ButtonTertiaryColorPositivePressedOnBackground,
  borderWidth: tkn_t.ButtonTertiaryBorderWidthPressed,
  borderColor: `${tkn_t.ButtonTertiaryColorPositivePressedOutline} !important`,
  zIndex: 2,
} as const
const primaryDestructiveActive = {
  backgroundColor: tkn_p.ButtonPrimaryColorDestructivePressedBackground,
  color: tkn_p.ButtonPrimaryColorDestructivePressedOnBackground,
  borderWidth: tkn_p.ButtonPrimaryBorderWidthPressed,
  borderColor: `${tkn_p.ButtonPrimaryColorDestructivePressedOutline} !important`,
  zIndex: 2,
} as const
const secondaryDestructiveActive = {
  backgroundColor: tkn_s.ButtonSecondaryColorDestructivePressedBackground,
  color: tkn_s.ButtonSecondaryColorDestructivePressedOnBackground,
  borderWidth: tkn_s.ButtonSecondaryBorderWidthPressed,
  borderColor: ` ${tkn_s.ButtonSecondaryColorDestructivePressedOutline} !important`,
  zIndex: 2,
} as const
const tertiaryDestructiveActive = {
  backgroundColor: tkn_t.ButtonTertiaryColorDestructivePressedBackground,
  color: tkn_t.ButtonTertiaryColorDestructivePressedOnBackground,
  borderWidth: tkn_t.ButtonTertiaryBorderWidthPressed,
  borderColor: `${tkn_t.ButtonTertiaryColorDestructivePressedOutline} !important`,
  zIndex: 2,
} as const
const primaryWarningActive = {
  backgroundColor: tkn_p.ButtonPrimaryColorWarningPressedBackground,
  color: tkn_p.ButtonPrimaryColorWarningPressedOnBackground,
  borderWidth: tkn_p.ButtonPrimaryBorderWidthPressed,
  borderColor: `${tkn_p.ButtonPrimaryColorWarningPressedOutline} !important`,
  zIndex: 2,
} as const
const secondaryWarningActive = {
  backgroundColor: tkn_s.ButtonSecondaryColorWarningPressedBackground,
  color: tkn_s.ButtonSecondaryColorWarningPressedOnBackground,
  borderWidth: tkn_p.ButtonPrimaryBorderWidthPressed,
  borderColor: `${tkn_s.ButtonSecondaryColorWarningPressedOutline} !important`,
  zIndex: 2,
} as const
const tertiaryWarningActive = {
  backgroundColor: tkn_t.ButtonTertiaryColorWarningPressedBackground,
  color: tkn_t.ButtonTertiaryColorWarningPressedOnBackground,
  borderWidth: tkn_p.ButtonPrimaryBorderWidthPressed,
  borderColor: `${tkn_t.ButtonTertiaryColorWarningPressedOutline} !important`,
  zIndex: 2,
} as const

export const sizeMedium = {
  height: tkn.ButtonBaseSizeDesktopMediumHeight,
  minWidth: tkn.ButtonBaseSizeDesktopMediumMinWidth,
  borderRadius: tkn.ButtonBaseBorderRadiusDesktopMedium,
  paddingLeft: tkn.ButtonBaseSpacingIconOffDesktopMediumLeft,
  paddingRight: tkn.ButtonBaseSpacingIconOffDesktopMediumRight,
} as const

export const Button = styledWithSelector(ButtonBase, {
  shouldForwardProp: youShallNotPass('kind', 'variant', 'size', 'startIcon', 'endIcon', 'fullWidth', 'active'),
})<TBaseButtonProps>(({ variant, kind, size, startIcon, endIcon, fullWidth, active }) => ({
  ...(fullWidth && { width: '100%' }),
  ...buttonBase,
  ...(size === 'large' && {
    height: tkn.ButtonBaseSizeDesktopLargeHeight,
    minWidth: tkn.ButtonBaseSizeDesktopLargeMinWidth,
    borderRadius: tkn.ButtonBaseBorderRadiusDesktopLarge,
    paddingLeft: tkn.ButtonBaseSpacingIconOffDesktopLargeLeft,
    paddingRight: tkn.ButtonBaseSpacingIconOffDesktopLargeRight,
  }),
  ...(size === 'medium' && {
    ...sizeMedium,
  }),
  ...(size === 'small' && {
    height: tkn.ButtonBaseSizeDesktopSmallHeight,
    minWidth: tkn.ButtonBaseSizeDesktopSmallMinWidth,
    borderRadius: tkn.ButtonBaseBorderRadiusDesktopSmall,
    paddingLeft: tkn.ButtonBaseSpacingIconOffDesktopSmallLeft,
    paddingRight: tkn.ButtonBaseSpacingIconOffDesktopSmallRight,
  }),
  ...(size === 'medium' &&
    startIcon && {
      paddingLeft: tkn.ButtonBaseSpacingIconLDesktopMediumLeft,
      paddingRight: tkn.ButtonBaseSpacingIconLDesktopMediumRight,
    }),
  ...(size === 'small' &&
    startIcon && {
      paddingLeft: tkn.ButtonBaseSpacingIconLDesktopSmallLeft,
      paddingRight: tkn.ButtonBaseSpacingIconLDesktopSmallRight,
    }),
  ...(size === 'large' &&
    startIcon && {
      paddingLeft: tkn.ButtonBaseSpacingIconLDesktopLargeLeft,
      paddingRight: tkn.ButtonBaseSpacingIconLDesktopLargeRight,
    }),
  ...(size === 'medium' &&
    endIcon && {
      paddingLeft: tkn.ButtonBaseSpacingIconRDesktopMediumLeft,
      paddingRight: tkn.ButtonBaseSpacingIconRDesktopMediumRight,
    }),
  ...(size === 'small' &&
    endIcon && {
      paddingLeft: tkn.ButtonBaseSpacingIconRDesktopSmallLeft,
      paddingRight: tkn.ButtonBaseSpacingIconRDesktopSmallRight,
    }),
  ...(size === 'large' &&
    endIcon && {
      paddingLeft: tkn.ButtonBaseSpacingIconRDesktopLargeLeft,
      paddingRight: tkn.ButtonBaseSpacingIconRDesktopLargeRight,
    }),
  ...(variant === 'primary' &&
    kind === 'neutral' && {
      ...primaryNeutral({ active }),
    }),
  ...(variant === 'secondary' &&
    kind === 'neutral' && {
      backgroundColor: tkn_s.ButtonSecondaryColorPositiveDefaultBackground,
      color: tkn_s.ButtonSecondaryColorPositiveDefaultOnBackground,
      borderWidth: tkn_s.ButtonSecondaryBorderWidthDefault,
      borderColor: tkn_s.ButtonSecondaryColorPositiveDefaultOutline,
      borderStyle: 'solid',
      ...(active && secondaryNeutralActive),
      ...(!active && {
        '&:active': secondaryNeutralActive,
        '&:hover': {
          backgroundColor: tkn_s.ButtonSecondaryColorPositiveHoveredBackground,
          color: tkn_s.ButtonSecondaryColorPositiveHoveredOnBackground,
          borderWidth: tkn_s.ButtonSecondaryBorderWidthHovered,
          borderColor: tkn_s.ButtonSecondaryColorPositiveHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn_s.ButtonSecondaryColorPositiveHoveredBackground,
        color: tkn_s.ButtonSecondaryColorPositiveHoveredOnBackground,
        borderWidth: tkn_s.ButtonSecondaryBorderWidthHovered,
        borderColor: tkn_s.ButtonSecondaryColorPositiveHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn_s.ButtonSecondaryColorPositiveDisabledBackground,
        color: tkn_s.ButtonSecondaryColorPositiveDisabledOnBackground,
        borderWidth: tkn_s.ButtonSecondaryBorderWidthDisabled,
        borderColor: tkn_s.ButtonSecondaryColorPositiveDisabledOutline,
      },
    }),
  ...(variant === 'tertiary' &&
    kind === 'neutral' && {
      backgroundColor: tkn_t.ButtonTertiaryColorPositiveDefaultBackground,
      color: tkn_t.ButtonTertiaryColorPositiveDefaultOnBackground,
      borderWidth: tkn_t.ButtonTertiaryBorderWidthDefault,
      borderStyle: 'solid',
      borderColor: tkn_t.ButtonTertiaryColorPositiveDefaultOutline,
      ...(active && tertiaryNeutralActive),
      ...(!active && {
        '&:active': tertiaryNeutralActive,
        '&:hover': {
          backgroundColor: tkn_t.ButtonTertiaryColorPositiveHoveredBackground,
          color: tkn_t.ButtonTertiaryColorPositiveHoveredOnBackground,
          borderWidth: tkn_t.ButtonTertiaryBorderWidthHovered,
          borderColor: tkn_t.ButtonTertiaryColorPositiveHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn_t.ButtonTertiaryColorPositiveHoveredBackground,
        color: tkn_t.ButtonTertiaryColorPositiveHoveredOnBackground,
        borderWidth: tkn_t.ButtonTertiaryBorderWidthHovered,
        borderColor: tkn_t.ButtonTertiaryColorPositiveHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn_t.ButtonTertiaryColorPositiveDisabledBackground,
        color: tkn_t.ButtonTertiaryColorPositiveDisabledOnBackground,
        borderWidth: tkn_t.ButtonTertiaryBorderWidthDisabled,
        borderColor: tkn_t.ButtonTertiaryColorPositiveDisabledOutline,
      },
    }),
  ...(variant === 'primary' &&
    kind === 'destructive' && {
      backgroundColor: tkn_p.ButtonPrimaryColorDestructiveDefaultBackground,
      color: tkn_p.ButtonPrimaryColorDestructiveDefaultOnBackground,
      borderWidth: tkn_p.ButtonPrimaryBorderWidthDefault,
      borderStyle: 'solid',
      borderColor: tkn_p.ButtonPrimaryColorDestructiveDefaultOutline,
      ...(active && primaryDestructiveActive),
      ...(!active && {
        '&:active': primaryDestructiveActive,
        '&:hover': {
          backgroundColor: tkn_p.ButtonPrimaryColorDestructiveHoveredBackground,
          color: tkn_p.ButtonPrimaryColorDestructiveHoveredOnBackground,
          borderWidth: tkn_p.ButtonPrimaryBorderWidthHovered,
          borderColor: tkn_p.ButtonPrimaryColorDestructiveHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn_p.ButtonPrimaryColorDestructiveHoveredBackground,
        color: tkn_p.ButtonPrimaryColorDestructiveHoveredOnBackground,
        borderWidth: tkn_p.ButtonPrimaryBorderWidthHovered,
        borderColor: tkn_p.ButtonPrimaryColorDestructiveHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn_p.ButtonPrimaryColorDestructiveDisabledBackground,
        color: tkn_p.ButtonPrimaryColorDestructiveDisabledOnBackground,
        borderWidth: tkn_p.ButtonPrimaryBorderWidthDisabled,
        borderColor: tkn_p.ButtonPrimaryColorDestructiveDisabledOutline,
      },
    }),
  ...(variant === 'secondary' &&
    kind === 'destructive' && {
      backgroundColor: tkn_s.ButtonSecondaryColorDestructiveDefaultBackground,
      color: tkn_s.ButtonSecondaryColorDestructiveDefaultOnBackground,
      borderWidth: tkn_s.ButtonSecondaryBorderWidthDefault,
      borderStyle: 'solid',
      borderColor: tkn_s.ButtonSecondaryColorDestructiveDefaultOutline,
      ...(active && secondaryDestructiveActive),
      ...(!active && {
        '&:active': secondaryDestructiveActive,
        '&:hover': {
          backgroundColor: tkn_s.ButtonSecondaryColorDestructiveHoveredBackground,
          color: tkn_s.ButtonSecondaryColorDestructiveHoveredOnBackground,
          borderWidth: tkn_s.ButtonSecondaryBorderWidthHovered,
          borderColor: tkn_s.ButtonSecondaryColorDestructiveHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn_s.ButtonSecondaryColorDestructiveHoveredBackground,
        color: tkn_s.ButtonSecondaryColorDestructiveHoveredOnBackground,
        borderWidth: tkn_s.ButtonSecondaryBorderWidthHovered,
        borderColor: tkn_s.ButtonSecondaryColorDestructiveHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn_s.ButtonSecondaryColorDestructiveDisabledBackground,
        color: tkn_s.ButtonSecondaryColorDestructiveDisabledOnBackground,
        borderWidth: tkn_s.ButtonSecondaryBorderWidthDisabled,
        borderColor: tkn_s.ButtonSecondaryColorDestructiveDisabledOutline,
      },
    }),
  ...(variant === 'tertiary' &&
    kind === 'destructive' && {
      backgroundColor: tkn_t.ButtonTertiaryColorDestructiveDefaultBackground,
      color: tkn_t.ButtonTertiaryColorDestructiveDefaultOnBackground,
      borderWidth: tkn_t.ButtonTertiaryBorderWidthDefault,
      borderStyle: 'solid',
      borderColor: tkn_t.ButtonTertiaryColorDestructiveDefaultOutline,
      ...(active && tertiaryDestructiveActive),
      ...(!active && {
        '&:active': tertiaryDestructiveActive,
        '&:hover': {
          backgroundColor: tkn_t.ButtonTertiaryColorDestructiveHoveredBackground,
          color: tkn_t.ButtonTertiaryColorDestructiveHoveredOnBackground,
          borderWidth: tkn_t.ButtonTertiaryBorderWidthHovered,
          borderColor: tkn_t.ButtonTertiaryColorDestructiveHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn_t.ButtonTertiaryColorDestructiveHoveredBackground,
        color: tkn_t.ButtonTertiaryColorDestructiveHoveredOnBackground,
        borderWidth: tkn_t.ButtonTertiaryBorderWidthHovered,
        borderColor: tkn_t.ButtonTertiaryColorDestructiveHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn_t.ButtonTertiaryColorDestructiveDisabledBackground,
        color: tkn_t.ButtonTertiaryColorDestructiveDisabledOnBackground,
        borderWidth: tkn_t.ButtonTertiaryBorderWidthDisabled,
        borderColor: tkn_t.ButtonTertiaryColorDestructiveDisabledOutline,
      },
      ...(active && {
        backgroundColor: tkn_t.ButtonTertiaryColorDestructivePressedBackground,
        color: tkn_t.ButtonTertiaryColorDestructivePressedOnBackground,
        borderWidth: tkn_t.ButtonTertiaryBorderWidthPressed,
        borderColor: tkn_t.ButtonTertiaryColorDestructivePressedOutline,
      }),
    }),
  ...(variant === 'primary' &&
    kind === 'warning' && {
      backgroundColor: tkn_p.ButtonPrimaryColorWarningDefaultBackground,
      color: tkn_p.ButtonPrimaryColorWarningDefaultOnBackground,
      borderWidth: tkn_p.ButtonPrimaryBorderWidthDefault,
      borderStyle: 'solid',
      borderColor: tkn_p.ButtonPrimaryColorWarningDefaultOutline,
      ...(active && primaryWarningActive),
      ...(!active && {
        '&:active': primaryWarningActive,
        '&:hover': {
          backgroundColor: tkn_p.ButtonPrimaryColorWarningHoveredBackground,
          color: tkn_p.ButtonPrimaryColorWarningDefaultOnBackground,
          borderWidth: tkn_p.ButtonPrimaryBorderWidthHovered,
          borderColor: tkn_p.ButtonPrimaryColorWarningHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn_p.ButtonPrimaryColorWarningHoveredBackground,
        color: tkn_p.ButtonPrimaryColorWarningHoveredOnBackground,
        borderWidth: tkn_p.ButtonPrimaryBorderWidthHovered,
        borderColor: tkn_p.ButtonPrimaryColorWarningHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn_p.ButtonPrimaryColorWarningDisabledBackground,
        color: tkn_p.ButtonPrimaryColorWarningDisabledOnBackground,
        borderWidth: tkn_p.ButtonPrimaryBorderWidthDisabled,
        borderColor: tkn_p.ButtonPrimaryColorWarningDisabledOutline,
      },
    }),
  ...(variant === 'secondary' &&
    kind === 'warning' && {
      backgroundColor: tkn_s.ButtonSecondaryColorWarningDefaultBackground,
      color: tkn_s.ButtonSecondaryColorWarningDefaultOnBackground,
      borderWidth: tkn_s.ButtonSecondaryBorderWidthDefault,
      borderStyle: 'solid',
      borderColor: tkn_s.ButtonSecondaryColorWarningDefaultOutline,
      ...(active && secondaryWarningActive),
      ...(!active && {
        '&:active': secondaryWarningActive,
        '&:hover': {
          backgroundColor: tkn_s.ButtonSecondaryColorWarningHoveredBackground,
          color: tkn_s.ButtonSecondaryColorWarningHoveredOnBackground,
          borderWidth: tkn_s.ButtonSecondaryBorderWidthHovered,
          borderColor: tkn_s.ButtonSecondaryColorWarningHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn_s.ButtonSecondaryColorWarningHoveredBackground,
        color: tkn_s.ButtonSecondaryColorWarningHoveredOnBackground,
        borderWidth: tkn_s.ButtonSecondaryBorderWidthHovered,
        borderColor: tkn_s.ButtonSecondaryColorWarningHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn_s.ButtonSecondaryColorWarningDisabledBackground,
        color: tkn_s.ButtonSecondaryColorWarningDisabledOnBackground,
        borderWidth: tkn_s.ButtonSecondaryBorderWidthDisabled,
        borderColor: tkn_s.ButtonSecondaryColorWarningDisabledOutline,
      },
    }),
  ...(variant === 'tertiary' &&
    kind === 'warning' && {
      backgroundColor: tkn_t.ButtonTertiaryColorWarningDefaultBackground,
      color: tkn_t.ButtonTertiaryColorWarningDefaultOnBackground,
      borderWidth: tkn_t.ButtonTertiaryBorderWidthDefault,
      borderStyle: 'solid',
      borderColor: tkn_t.ButtonTertiaryColorWarningDefaultOutline,
      ...(active && tertiaryWarningActive),
      ...(!active && {
        '&:active': tertiaryWarningActive,
        '&:hover': {
          backgroundColor: tkn_t.ButtonTertiaryColorWarningHoveredBackground,
          color: tkn_t.ButtonTertiaryColorWarningHoveredOnBackground,
          borderWidth: tkn_t.ButtonTertiaryBorderWidthHovered,
          borderColor: tkn_t.ButtonTertiaryColorWarningHoveredOutline,
        },
      }),
      '&:focus-visible': {
        backgroundColor: tkn_t.ButtonTertiaryColorWarningHoveredBackground,
        color: tkn_t.ButtonTertiaryColorWarningHoveredOnBackground,
        borderWidth: tkn_t.ButtonTertiaryBorderWidthHovered,
        borderColor: tkn_t.ButtonTertiaryColorWarningHoveredOutline,
      },
      '&:disabled': {
        backgroundColor: tkn_t.ButtonTertiaryColorWarningDisabledBackground,
        color: tkn_t.ButtonTertiaryColorWarningDisabledOnBackground,
        borderWidth: tkn_t.ButtonTertiaryBorderWidthDisabled,
        borderColor: tkn_t.ButtonTertiaryColorWarningDisabledOutline,
      },
      ...(active && {
        backgroundColor: tkn_t.ButtonTertiaryColorWarningPressedBackground,
        color: tkn_t.ButtonTertiaryColorWarningPressedOnBackground,
        borderWidth: tkn_t.ButtonTertiaryBorderWidthPressed,
        borderColor: tkn_t.ButtonTertiaryColorWarningPressedOutline,
      }),
    }),
}))

type TStartIconBox = BoxProps & Pick<TButtonProps, 'size'>
const StartIconBox = styled(Box, {
  shouldForwardProp: youShallNotPass('size'),
})<TStartIconBox>(({ size }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  height: '24px',

  ...(size === 'large' && {
    marginRight: tkn.ButtonBaseSpacingIconLDesktopLargeGap,
  }),
  ...(size === 'medium' && {
    marginRight: tkn.ButtonBaseSpacingIconLDesktopMediumGap,
  }),
  ...(size === 'small' && {
    marginRight: tkn.ButtonBaseSpacingIconLDesktopSmallGap,
  }),
}))

type TEndIconBox = BoxProps & Pick<TButtonProps, 'size'>
const EndIconBox = styled(Box, {
  shouldForwardProp: youShallNotPass('size'),
})<TEndIconBox>(({ size }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  height: '24px',

  ...(size === 'small' && {
    marginLeft: tkn.ButtonBaseSpacingIconRDesktopSmallGap,
  }),
  ...(size === 'medium' && {
    marginLeft: tkn.ButtonBaseSpacingIconRDesktopMediumGap,
  }),
  ...(size === 'large' && {
    marginLeft: tkn.ButtonBaseSpacingIconRDesktopLargeGap,
  }),
}))

export default {
  Button,
  StartIconBox,
  EndIconBox,
}
