import { Typography, Stack, styled } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { IconButton } from 'settle-ui/components/IconButton/IconButton'
import { Button as SuiButton } from 'settle-ui/components/Button/Button'
import { youShallNotPass } from 'lib/helpers/youShallNotPass/youShallNotPass'
import type { TSnackbarVariant } from '../Snackbars/Snackbars'
import * as tkn from './Snackbar.tokens'

interface ISnackbarProps {
  variant: TSnackbarVariant
}

export const Root = styled('div')(() => ({
  display: 'inline-block',
  marginBottom: tkn.SnackbarSpacingMediumDesktopGap,
}))

export const Snackbar = styled(Stack, {
  shouldForwardProp: youShallNotPass('variant'),
})<ISnackbarProps>(({ theme, ...props }) => ({
  display: 'flex',
  paddingLeft: tkn.SnackbarSpacingMediumDesktopHorizontal,
  paddingRight: tkn.SnackbarSpacingMediumDesktopHorizontal,
  paddingTop: tkn.SnackbarSpacingMediumDesktopVertical,
  paddingBottom: tkn.SnackbarSpacingMediumDesktopVertical,
  minHeight: tkn.SnackbarSizeDesktop,
  minWidth: '428px',
  flexGrow: 1,
  alignItems: 'center',
  borderRadius: tkn.SnackbarBorderRadiusDesktop,
  boxShadow: `
      0px 3px 5px -1px rgba(0,0,0,0.2),
      0px 6px 10px 0px rgba(0,0,0,0.14),
      0px 1px 18px 0px rgba(0,0,0,0.12)`,
  textAlign: 'left',
  ...(props.variant === 'error' && {
    backgroundColor: tkn.SnackbarColorErrorBackground,
    color: tkn.SnackbarColorErrorOnBackground,
  }),
  ...(props.variant === 'alert' && {
    backgroundColor: tkn.SnackbarColorSuccessBackground,
    color: tkn.SnackbarColorSuccessOnBackground,
  }),
  ...(props.variant === 'warning' && {
    backgroundColor: tkn.SnackbarColorWarningBackground,
    color: tkn.SnackbarColorWarningOnBackground,
  }),
  ...(props.variant === 'info' && {
    backgroundColor: tkn.SnackbarColorInfoBackground,
    color: tkn.SnackbarColorInfoOnBackground,
  }),
  [theme.breakpoints.down('md')]: {
    minWidth: '320px',
  },
}))

export const Message = styled(Typography)(() => ({
  flexGrow: 1,
  color: '#fff',
}))

export const Spinner = styled(CircularProgress)(() => ({
  flexShrink: 0,
  color: '#fff',
}))

export const Close = styled(IconButton)(() => ({
  flexShrink: 0,
  color: 'inherit',
  '&:hover': {
    color: 'inherit',
  },
}))

export const Button = styled(SuiButton)(() => ({
  minWidth: 'auto',
  color: 'inherit',
  '&:hover': {
    color: 'inherit',
  },
}))
