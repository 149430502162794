import { classNames } from 'settle-ui/theme'

export const setScrollbarStrategy = () => {
  const parent = document.createElement('div')
  const child = document.createElement('div')

  parent.setAttribute('style', 'width:30px;height:30px;overflow: auto;')
  child.setAttribute('style', 'width:100%;height:40px')

  parent.appendChild(child)
  document.body.appendChild(parent)

  const scrollbarWidth = 30 - child.clientWidth

  if (scrollbarWidth) {
    document.body.classList.add(classNames.obtrusiveScrollbar)
  }

  document.body.removeChild(parent)
}
