// eslint-disable-next-line no-restricted-imports
import type { rootShouldForwardProp } from '@mui/material/styles/styled'

/**
 * Helper for `styled()`-components.
 *
 * Accepts list of prop names that should be blacklisted and returns
 * the `shouldForwardProp` handler (method of optional `styled()` object)
 * that tells `styled()` which properties should be forwarded to the wrapped component.
 *
 * Prop names provided as array of strings (or any quantity of string arguments) to
 * this helper will not be passed/forwarded to DOM.
 * @param {...string} blacklist - array of blacklisted prop names that shouldn't be forwarded
 * @returns {function} - typeof shouldForwardProp
 */
export const youShallNotPass =
  <T = string>(...blacklist: (T | T[])[]): typeof rootShouldForwardProp =>
  (propName) =>
    !blacklist.flat().map(String).includes(String(propName))
