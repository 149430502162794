import { useBusiness } from 'lib/hooks/useBusiness/useBusiness'
import { ProviderEnum } from 'gql/types'

export const useConnectedAccountingPlatform = () => {
  const { business } = useBusiness()

  switch (true) {
    case business?.quickBooksLinked:
      return ProviderEnum.QUICK_BOOKS
    case business?.xeroLinked:
      return ProviderEnum.XERO
    case business?.netsuiteLinked:
      return ProviderEnum.NETSUITE
    case business?.finaloopLinked:
      return ProviderEnum.FINALOOP
    default:
      return null
  }
}
