import { z } from 'zod'

export { enumParse } from './helpers/enumParse/enumParse'

/**
 * Get a string query parameter.
 */
export const qsStringSchema = z.string({ coerce: true }).optional().default('')

/**
 * Get a string query parameter.
 * If missing, return null.
 */
export const qsSchema = z.string({ coerce: true }).nullable().optional().default(null)
