import type { Theme } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { theme, prideMonthTheme } from 'settle-ui/theme'

export const useMUITheme = (): Theme => {
  const { muiTheme } = useFlags()

  if (muiTheme === 'pride-month') {
    return prideMonthTheme
  }

  return theme
}
