import { Collapse, LinearProgress } from '@mui/material'
import { useNProgress } from '@tanem/react-nprogress'
import { useEffect, useState } from 'react'
import { useAppProgressStore } from 'pages/components/AppProgress/hooks/useAppProgressStore/useAppProgressStore'

export const Progress: React.FC = () => {
  const isRouterChanging = useAppProgressStore((store) => store.isRouterChanging)
  const [isAnimating, setIsAnimating] = useState(false)
  const { isFinished, progress } = useNProgress({ isAnimating })
  const progressInPercentage = progress * 100

  /* Avoid flickering when route transition is fast */
  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (isRouterChanging) {
      timeout = setTimeout(() => setIsAnimating(true), 500)
    } else {
      setIsAnimating(false)
    }

    return () => clearTimeout(timeout)
  }, [isRouterChanging])

  return (
    <Collapse in={!isFinished} unmountOnExit={true}>
      <LinearProgress
        color="secondary"
        variant="determinate"
        value={progressInPercentage}
        sx={{
          top: 0,
          height: { md: '4px' },
          width: '100%',
          zIndex: 9999,
          position: 'fixed',
        }}
      />
    </Collapse>
  )
}
