/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const SelectorSizeDesktopLarge = '48px'
export const SelectorSizeDesktopMedium = '40px'
export const SelectorSizeDesktopSmall = '32px'
export const SelectorSizeMobileLarge = '48px'
export const SelectorSizeMobileMedium = '40px'
export const SelectorSizeMobileSmall = '32px'
export const SelectorBorderRadiusDesktopLarge = '24px'
export const SelectorBorderRadiusDesktopMedium = '20px'
export const SelectorBorderRadiusDesktopSmall = '16px'
export const SelectorBorderRadiusMobileLarge = '24px'
export const SelectorBorderRadiusMobileMedium = '20px'
export const SelectorBorderRadiusMobileSmall = '16px'
export const SelectorColorUnselectedDefaultBody = '#00000099'
export const SelectorColorUnselectedDefaultHighlight = '#ffffff00'
export const SelectorColorUnselectedHoveredBody = '#00000099'
export const SelectorColorUnselectedHoveredHighlight = '#00000014'
export const SelectorColorUnselectedDisabledBody = '#0000001f'
export const SelectorColorUnselectedDisabledHighlight = '#ffffff00'
export const SelectorColorSelectedDefaultBody = '#3E8672'
export const SelectorColorSelectedDefaultHighlight = '#ffffff00'
export const SelectorColorSelectedHoveredBody = '#3E8672'
export const SelectorColorSelectedHoveredHighlight = '#004d3b14'
export const SelectorColorSelectedDisabledBody = '#0000001f'
export const SelectorColorSelectedDisabledHighlight = '#ffffff00'
export const SelectorColorIndeterminateDefaultBody = '#3E8672'
export const SelectorColorIndeterminateDefaultHighlight = '#ffffff00'
export const SelectorColorIndeterminateHoveredBody = '#3E8672'
export const SelectorColorIndeterminateHoveredHighlight = '#004d3b14'
export const SelectorColorIndeterminateDisabledBody = '#0000001f'
export const SelectorColorIndeterminateDisabledHighlight = '#ffffff00'
