/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const ChipSizeDesktopLarge = '40px'
export const ChipSizeDesktopMedium = '32px'
export const ChipSizeDesktopSmall = '24px'
export const ChipSizeMobileLarge = '40px'
export const ChipSizeMobileMedium = '32px'
export const ChipSizeMobileSmall = '24px'
export const ChipBorderRadiusDesktopLarge = '20px'
export const ChipBorderRadiusDesktopMedium = '16px'
export const ChipBorderRadiusDesktopSmall = '12px'
export const ChipBorderRadiusMobileLarge = '20px'
export const ChipBorderRadiusMobileMedium = '16px'
export const ChipBorderRadiusMobileSmall = '12px'
export const ChipSpacingIconOffDesktopSmallLeft = '8px'
export const ChipSpacingIconOffDesktopSmallRight = '8px'
export const ChipSpacingIconOffDesktopMediumLeft = '12px'
export const ChipSpacingIconOffDesktopMediumRight = '12px'
export const ChipSpacingIconOffDesktopLargeLeft = '16px'
export const ChipSpacingIconOffDesktopLargeRight = '16px'
export const ChipSpacingIconOffMobileSmallLeft = '8px'
export const ChipSpacingIconOffMobileSmallRight = '8px'
export const ChipSpacingIconOffMobileMediumLeft = '12px'
export const ChipSpacingIconOffMobileMediumRight = '12px'
export const ChipSpacingIconOffMobileLargeLeft = '16px'
export const ChipSpacingIconOffMobileLargeRight = '16px'
export const ChipSpacingIconLDesktopSmallLeft = '0px'
export const ChipSpacingIconLDesktopSmallRight = '8px'
export const ChipSpacingIconLDesktopSmallGap = '4px'
export const ChipSpacingIconLDesktopMediumLeft = '4px'
export const ChipSpacingIconLDesktopMediumRight = '12px'
export const ChipSpacingIconLDesktopMediumGap = '8px'
export const ChipSpacingIconLDesktopLargeLeft = '8px'
export const ChipSpacingIconLDesktopLargeRight = '16px'
export const ChipSpacingIconLDesktopLargeGap = '12px'
export const ChipSpacingIconLMobileSmallLeft = '0px'
export const ChipSpacingIconLMobileSmallRight = '8px'
export const ChipSpacingIconLMobileSmallGap = '4px'
export const ChipSpacingIconLMobileMediumLeft = '4px'
export const ChipSpacingIconLMobileMediumRight = '12px'
export const ChipSpacingIconLMobileMediumGap = '8px'
export const ChipSpacingIconLMobileLargeLeft = '8px'
export const ChipSpacingIconLMobileLargeRight = '16px'
export const ChipSpacingIconLMobileLargeGap = '12px'
export const ChipSpacingIconRDesktopSmallLeft = '8px'
export const ChipSpacingIconRDesktopSmallRight = '0px'
export const ChipSpacingIconRDesktopSmallGap = '4px'
export const ChipSpacingIconRDesktopMediumLeft = '12px'
export const ChipSpacingIconRDesktopMediumRight = '4px'
export const ChipSpacingIconRDesktopMediumGap = '8px'
export const ChipSpacingIconRDesktopLargeLeft = '16px'
export const ChipSpacingIconRDesktopLargeRight = '8px'
export const ChipSpacingIconRDesktopLargeGap = '12px'
export const ChipSpacingIconRMobileSmallLeft = '8px'
export const ChipSpacingIconRMobileSmallRight = '0px'
export const ChipSpacingIconRMobileSmallGap = '4px'
export const ChipSpacingIconRMobileMediumLeft = '12px'
export const ChipSpacingIconRMobileMediumRight = '4px'
export const ChipSpacingIconRMobileMediumGap = '8px'
export const ChipSpacingIconRMobileLargeLeft = '16px'
export const ChipSpacingIconRMobileLargeRight = '8px'
export const ChipSpacingIconRMobileLargeGap = '12px'
export const ChipSpacingIconLrDesktopSmallLeft = '0px'
export const ChipSpacingIconLrDesktopSmallRight = '0px'
export const ChipSpacingIconLrDesktopSmallGap = '4px'
export const ChipSpacingIconLrDesktopMediumLeft = '4px'
export const ChipSpacingIconLrDesktopMediumRight = '4px'
export const ChipSpacingIconLrDesktopMediumGap = '8px'
export const ChipSpacingIconLrDesktopLargeLeft = '8px'
export const ChipSpacingIconLrDesktopLargeRight = '8px'
export const ChipSpacingIconLrDesktopLargeGap = '12px'
export const ChipSpacingIconLrMobileSmallLeft = '0px'
export const ChipSpacingIconLrMobileSmallRight = '0px'
export const ChipSpacingIconLrMobileSmallGap = '4px'
export const ChipSpacingIconLrMobileMediumLeft = '4px'
export const ChipSpacingIconLrMobileMediumRight = '4px'
export const ChipSpacingIconLrMobileMediumGap = '8px'
export const ChipSpacingIconLrMobileLargeLeft = '8px'
export const ChipSpacingIconLrMobileLargeRight = '8px'
export const ChipSpacingIconLrMobileLargeGap = '12px'
export const ChipBorderWidthDefault = '0px'
export const ChipBorderWidthHovered = '0px'
export const ChipBorderWidthPressed = '0px'
export const ChipBorderWidthDisabled = '0px'
export const ChipColorDefaultBackground = '#0000000a'
export const ChipColorDefaultText = '#00000099'
export const ChipColorDefaultIcon = '#00000061'
export const ChipColorDefaultOutline = '#ffffff00'
export const ChipColorHoveredBackground = '#00000014'
export const ChipColorHoveredText = '#000000de'
export const ChipColorHoveredIcon = '#00000061'
export const ChipColorHoveredOutline = '#ffffff00'
export const ChipColorSelectedBackground = '#3e86721f'
export const ChipColorSelectedText = '#3E8672'
export const ChipColorSelectedIcon = '#00000061'
export const ChipColorSelectedOutline = '#ffffff00'
export const ChipColorDisabledBackground = '#0000000a'
export const ChipColorDisabledText = '#00000061'
export const ChipColorDisabledIcon = '#00000061'
export const ChipColorDisabledOutline = '#ffffff00'
