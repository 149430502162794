/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const IconButtonTertiaryBorderWidthDefault = '0px'
export const IconButtonTertiaryBorderWidthHovered = '0px'
export const IconButtonTertiaryBorderWidthPressed = '0px'
export const IconButtonTertiaryBorderWidthDisabled = '0px'
export const IconButtonTertiaryColorPositiveDefaultBackground = '#ffffff00'
export const IconButtonTertiaryColorPositiveDefaultOnBackground = '#00000099'
export const IconButtonTertiaryColorPositiveDefaultOutline = '#ffffff00'
export const IconButtonTertiaryColorPositiveHoveredBackground = '#00000014'
export const IconButtonTertiaryColorPositiveHoveredOnBackground = '#00000099'
export const IconButtonTertiaryColorPositiveHoveredOutline = '#ffffff00'
export const IconButtonTertiaryColorPositivePressedBackground = '#00000029'
export const IconButtonTertiaryColorPositivePressedOnBackground = '#00000099'
export const IconButtonTertiaryColorPositivePressedOutline = '#ffffff00'
export const IconButtonTertiaryColorPositiveDisabledBackground = '#ffffff00'
export const IconButtonTertiaryColorPositiveDisabledOnBackground = '#00000061'
export const IconButtonTertiaryColorPositiveDisabledOutline = '#ffffff00'
export const IconButtonTertiaryColorDestructiveDefaultBackground = '#ffffff00'
export const IconButtonTertiaryColorDestructiveDefaultOnBackground = '#f4511e'
export const IconButtonTertiaryColorDestructiveDefaultOutline = '#ffffff00'
export const IconButtonTertiaryColorDestructiveHoveredBackground = '#f4511e14'
export const IconButtonTertiaryColorDestructiveHoveredOnBackground = '#f4511e'
export const IconButtonTertiaryColorDestructiveHoveredOutline = '#ffffff00'
export const IconButtonTertiaryColorDestructivePressedBackground = '#f4511e29'
export const IconButtonTertiaryColorDestructivePressedOnBackground = '#f4511e'
export const IconButtonTertiaryColorDestructivePressedOutline = '#ffffff00'
export const IconButtonTertiaryColorDestructiveDisabledBackground = '#ffffff00'
export const IconButtonTertiaryColorDestructiveDisabledOnBackground = '#00000061'
export const IconButtonTertiaryColorDestructiveDisabledOutline = '#ffffff00'
export const IconButtonTertiaryColorWarningDefaultBackground = '#ffffff00'
export const IconButtonTertiaryColorWarningDefaultWarning = '#fb8c00'
export const IconButtonTertiaryColorWarningDefaultOutline = '#ffffff00'
export const IconButtonTertiaryColorWarningHoveredBackground = '#fb8c0014'
export const IconButtonTertiaryColorWarningHoveredOnBackground = '#fb8c00'
export const IconButtonTertiaryColorWarningHoveredOutline = '#ffffff00'
export const IconButtonTertiaryColorWarningPressedBackground = '#fb8c0029'
export const IconButtonTertiaryColorWarningPressedOnBackground = '#fb8c00'
export const IconButtonTertiaryColorWarningPressedOutline = '#ffffff00'
export const IconButtonTertiaryColorWarningDisabledBackground = '#ffffff00'
export const IconButtonTertiaryColorWarningDisabledOnBackground = '#00000061'
export const IconButtonTertiaryColorWarningDisabledOutline = '#ffffff00'
