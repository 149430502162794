import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useBusinessIdPathParam } from 'lib/navigation/hooks/useBusinessIdPathParam/useBusinessIdPathParam'
import { ProfileHookDocument } from 'lib/hooks/gql/useProfileHook.graphql'

export const useMembershipRole = () => {
  const businessId = useBusinessIdPathParam()
  const { data } = useQuery(ProfileHookDocument)

  return useMemo(() => {
    const memberships = data?.profile?.memberships
    const membership = memberships?.find(({ business }) => business?.id === businessId)

    return membership?.role
  }, [data?.profile?.memberships, businessId])
}
