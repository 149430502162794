/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const TooltipColorDefaultBackground = '#212121'
export const TooltipColorDefaultText = '#ffffff'
export const TooltipColorDefaultOutline = '#ffffff00'
export const TooltipSizeMinHeightDesktop = '24px'
export const TooltipSizeMinHeightMobile = '24px'
export const TooltipSizeMaxWidthDesktop = '216px'
export const TooltipSizeMaxWidthMobile = '216px'
export const TooltipSpacingDesktopVertical = '4px'
export const TooltipSpacingDesktopMarginTop = '8px'
export const TooltipSpacingDesktopHorizontal = '8px'
export const TooltipSpacingMobileVertical = '4px'
export const TooltipSpacingMobileMarginTop = '8px'
export const TooltipSpacingMobileHorizontal = '8px'
export const TooltipBorderRadiusDesktop = '4px'
export const TooltipBorderRadiusMobile = '4px'
export const TooltipBorderWidthDefault = '0px'
export const TooltipShadowDesktopX = '0px'
export const TooltipShadowDesktopY = '0px'
export const TooltipShadowDesktopBlur = '8px'
export const TooltipShadowDesktopSpread = '0px'
export const TooltipShadowDesktopColor = '#00000026'
export const TooltipShadowDesktopType = 'dropShadow'
export const TooltipShadowMobileX = '0px'
export const TooltipShadowMobileY = '0px'
export const TooltipShadowMobileBlur = '8px'
export const TooltipShadowMobileSpread = '0px'
export const TooltipShadowMobileColor = '#00000026'
export const TooltipShadowMobileType = 'dropShadow'
export const TooltipTypographyDesktopFontFamily = 'Inter'
export const TooltipTypographyDesktopFontWeight = 400
export const TooltipTypographyDesktopLineHeight = '16px'
export const TooltipTypographyDesktopFontSize = '12px'
export const TooltipTypographyDesktopLetterSpacing = '0.4px'
export const TooltipTypographyDesktopParagraphSpacing = '0px'
export const TooltipTypographyDesktopTextDecoration = ' '
export const TooltipTypographyDesktopTextCase = ' '
export const TooltipTypographyMobileFontFamily = 'Inter'
export const TooltipTypographyMobileFontWeight = 400
export const TooltipTypographyMobileLineHeight = '16px'
export const TooltipTypographyMobileFontSize = '12px'
export const TooltipTypographyMobileLetterSpacing = '0.4px'
export const TooltipTypographyMobileParagraphSpacing = '0px'
export const TooltipTypographyMobileTextDecoration = ' '
export const TooltipTypographyMobileTextCase = ' '
