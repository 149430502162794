import { create } from 'zustand'

interface IAppProgressStore {
  isRouterChanging: boolean
  key: 0 | 1
  start: () => void
  end: () => void
}

export const useAppProgressStore = create<IAppProgressStore>((set) => ({
  key: 0,
  isRouterChanging: false,
  end: () => set(() => ({ isRouterChanging: false })),
  start: () => set(({ key }) => ({ isRouterChanging: true, key: key === 0 ? 1 : 0 })),
}))
