/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const InputOutlinedSpacingMediumLabel = '0 0 0 8px'
export const InputOutlinedSpacingLargeLabel = '0 0 0 12px'
export const InputOutlinedBorderWidthDefault = '1px'
export const InputOutlinedBorderWidthHovered = '2px'
export const InputOutlinedBorderWidthFocused = '2px'
export const InputOutlinedBorderWidthDisabled = '1px'
export const InputOutlinedBorderWidthError = '1px'
export const InputOutlinedBorderWidthErrorFocused = '2px'
export const InputOutlinedBorderWidthWarning = '1px'
export const InputOutlinedBorderWidthWarningFocused = '2px'
export const InputOutlinedColorIdleBackground = '#ffffff'
export const InputOutlinedColorIdleLeadingIcon = '#00000061'
export const InputOutlinedColorIdleTrailingIcon = '#00000099'
export const InputOutlinedColorIdleOutline = '#0000001f'
export const InputOutlinedColorIdleText = '#00000099'
export const InputOutlinedColorIdleLabel = '#00000099'
export const InputOutlinedColorIdleHelper = '#00000099'
export const InputOutlinedColorDefaultBackground = '#ffffff'
export const InputOutlinedColorDefaultLeadingIcon = '#00000061'
export const InputOutlinedColorDefaultTrailingIcon = '#00000099'
export const InputOutlinedColorDefaultOutline = '#0000001f'
export const InputOutlinedColorDefaultText = '#000000de'
export const InputOutlinedColorDefaultLabel = '#00000099'
export const InputOutlinedColorDefaultHelper = '#00000099'
export const InputOutlinedColorHoveredBackground = '#ffffff'
export const InputOutlinedColorHoveredLeadingIcon = '#00000061'
export const InputOutlinedColorHoveredTrailingIcon = '#00000099'
export const InputOutlinedColorHoveredOutline = '#0000001f'
export const InputOutlinedColorHoveredText = '#000000de'
export const InputOutlinedColorHoveredLabel = '#00000099'
export const InputOutlinedColorHoveredHelper = '#00000099'
export const InputOutlinedColorFocusedBackground = '#ffffff'
export const InputOutlinedColorFocusedLeadingIcon = '#00000061'
export const InputOutlinedColorFocusedTrailingIcon = '#00000099'
export const InputOutlinedColorFocusedOutline = '#00897b'
export const InputOutlinedColorFocusedText = '#000000de'
export const InputOutlinedColorFocusedLabel = '#00897b'
export const InputOutlinedColorFocusedHelper = '#00000099'
export const InputOutlinedColorDisabledBackground = '#ffffff'
export const InputOutlinedColorDisabledLeadingIcon = '#00000061'
export const InputOutlinedColorDisabledTrailingIcon = '#00000061'
export const InputOutlinedColorDisabledOutline = '#0000001f'
export const InputOutlinedColorDisabledText = '#00000061'
export const InputOutlinedColorDisabledLabel = '#00000061'
export const InputOutlinedColorDisabledHelper = '#00000061'
export const InputOutlinedColorErrorBackground = '#ffffff'
export const InputOutlinedColorErrorLeadingIcon = '#00000061'
export const InputOutlinedColorErrorTrailingIcon = '#f4511e'
export const InputOutlinedColorErrorOutline = '#f4511e'
export const InputOutlinedColorErrorText = '#000000de'
export const InputOutlinedColorErrorLabel = '#f4511e'
export const InputOutlinedColorErrorHelper = '#f4511e'
export const InputOutlinedColorErrorFocusedBackground = '#ffffff'
export const InputOutlinedColorErrorFocusedLeadingIcon = '#00000061'
export const InputOutlinedColorErrorFocusedTrailingIcon = '#f4511e'
export const InputOutlinedColorErrorFocusedOutline = '#f4511e'
export const InputOutlinedColorErrorFocusedText = '#000000de'
export const InputOutlinedColorErrorFocusedLabel = '#f4511e'
export const InputOutlinedColorErrorFocusedHelper = '#f4511e'
export const InputOutlinedColorWarningBackground = '#ffffff'
export const InputOutlinedColorWarningLeadingIcon = '#00000061'
export const InputOutlinedColorWarningTrailingIcon = '#fb8c00'
export const InputOutlinedColorWarningOutline = '#fb8c00'
export const InputOutlinedColorWarningText = '#000000de'
export const InputOutlinedColorWarningLabel = '#fb8c00'
export const InputOutlinedColorWarningHelper = '#fb8c00'
export const InputOutlinedColorWarningFocusedBackground = '#ffffff'
export const InputOutlinedColorWarningFocusedLeadingIcon = '#00000061'
export const InputOutlinedColorWarningFocusedTrailingIcon = '#fb8c00'
export const InputOutlinedColorWarningFocusedOutline = '#fb8c00'
export const InputOutlinedColorWarningFocusedText = '#000000de'
export const InputOutlinedColorWarningFocusedLabel = '#fb8c00'
export const InputOutlinedColorWarningFocusedHelper = '#fb8c00'
