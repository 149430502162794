import { generateUUID } from 'lib/helpers/generateUUID/generateUUID'
import type { ISnackbar } from '../../useSnackbarsStore'

export const getSnackbarWithKey = (snackbar: Omit<ISnackbar, 'key' | 'id'>): ISnackbar => {
  const key = generateUUID()

  return {
    key,
    ...snackbar,
    id: snackbar.options?.id ?? key,
  }
}
