import type { Router } from 'next/router'

export const getOrderedParamKeys = <T extends string>(asPath: Router['asPath'], searchedKeys: T[]) => {
  const queryString = asPath.split('?')[1]

  if (!queryString) {
    return []
  }

  // extract the keys from key-value pairs in the order they appear
  const paramKeys = queryString.split('&').reduce<T[]>((keys, pair) => {
    const key = pair.split('=')[0] as T

    return key && searchedKeys.includes(key) ? [...keys, key] : keys
  }, [])

  return paramKeys
}
