import type { Router } from 'next/router'
import type { TSettingsDialogTab } from 'pages/dialogs/SettingsDialog/SettingsDialog.types'
import { parseQueryValue } from '../parseQueryValue/parseQueryValue'

export const getSettingsQueryAsLocation = (query: Router['query']): string => {
  const location = 'settings'

  const settingsQueryValue = parseQueryValue<TSettingsDialogTab>(query.settings)

  if (!settingsQueryValue) {
    return location
  }

  if (settingsQueryValue === 'accounting') {
    return location + '/accounting'
  }

  if (settingsQueryValue === 'business-details') {
    return location + '/business'
  }

  if (settingsQueryValue === 'accounts-payable') {
    return location + '/accounts-payable'
  }

  if (settingsQueryValue === 'plan') {
    return location + '/plan'
  }

  if (settingsQueryValue === 'notifications') {
    return location + '/notifications'
  }

  if (settingsQueryValue === 'profile') {
    return location + '/profile'
  }

  if (settingsQueryValue === 'bank-accounts') {
    return location + '/bank-accounts'
  }

  if (/^(?=.*link)(?=.*bank)(?=.*account).*$/.test(settingsQueryValue)) {
    return location + '/bank-accounts/link-account'
  }

  if (settingsQueryValue === 'rules') {
    return location + '/rules' + ('rule_id' in query ? '/edit-rule' : '')
  }

  if (settingsQueryValue === 'rules-new') {
    return location + '/rules/add-rule'
  }

  if (settingsQueryValue === 'roles') {
    return location + '/user-roles' + ('role_id' in query ? '/edit-role' : '')
  }

  if (settingsQueryValue === 'roles-new') {
    return location + '/user-roles/add-role'
  }

  if (settingsQueryValue === 'team-members') {
    return location + '/team-members'
  }

  if (settingsQueryValue === 'team-members-invite') {
    return location + '/team-members/invite-member'
  }

  if (settingsQueryValue === 'team-members-edit') {
    return location + '/team-members/edit-member'
  }

  if (settingsQueryValue === 'manage-plan') {
    return location + '/plan/manage-plan'
  }

  return location
}
