import type { O } from 'ts-toolbelt'
import type { IdentifyParams } from '@segment/analytics-node/dist/types'
import { isSSR } from 'lib/helpers/isSSR/isSSR'
import type { TIdentifyBody } from 'pages/api/segment/identify.api'
import type { TTrackBody } from 'pages/api/segment/track.api'
import type { TPageBody } from 'pages/api/segment/page.api'

const getServerSideContext = (): IdentifyParams['context'] => ({
  page: {
    path: globalThis.location?.pathname,
    referrer: globalThis.document?.referrer,
    search: globalThis.location?.search,
    title: globalThis.document?.title,
    url: globalThis.location?.href,
  },
  timezone: isSSR() ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
  userAgent: globalThis.navigator?.userAgent,
  locale: globalThis.navigator?.language,
})

const segment = async (action: 'identify' | 'track' | 'page', body: TIdentifyBody | TTrackBody | TPageBody) => {
  const origin = globalThis.location?.origin

  if (!process.env.SEGMENT_SSR_WRITE_KEY || !origin) {
    return undefined
  }

  const enrichedBody: typeof body = {
    timestamp: Date.now().toString(),
    ...body,
    context: {
      ...body.context,
      ...getServerSideContext(),
    },
  }

  try {
    return await fetch(`${origin}/api/segment/${action}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(enrichedBody),
    })
  } catch (err) {
    console.error(`Error sending Segment event: ${err}`)
    return null
  }
}

export const segmentServerSide = {
  identify: (body: TIdentifyBody) => segment('identify', body),
  track: (body: TTrackBody) => segment('track', body),
  page: (body: O.Optional<TPageBody, 'name'>) =>
    segment('page', {
      ...body,
      name: body.name || (isSSR() ? '' : window.location.pathname),
      properties: getServerSideContext()?.page,
    }),
} as const
