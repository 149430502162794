import { useEffect } from 'react'
import { updateUser } from '../../../../../../lib/tracking/datadog'
import { useUserTraits } from '../../../hooks/useUserTraits/useUserTraits'

export const useDatadogIdentityTracking = (profileId: string) => {
  const traits = useUserTraits()

  // update user if userId or traits change
  useEffect(() => {
    if (profileId) {
      updateUser(profileId, traits || {})
    }
  }, [profileId, traits])
}
