import { getCookie } from 'cookies-next'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { GetServerSidePropsContext } from 'next/types'
import { cookieKeys } from 'lib/consts/browserStorage'

/**
 * Returns true if the session is from a Settle admin impersonating a business.
 * Returns false for regular user sessions.
 * @param {Object} ctx - optional next.js context from getServerSideProps
 * @returns {Boolean}
 */
export const isImpersonationSession = (ctx?: GetServerSidePropsContext) => {
  const token = getCookie(cookieKeys.token, ctx)

  if (typeof token === 'string') {
    try {
      const decoded = jwt_decode<JwtPayload>(token)

      if (decoded && decoded.iss) {
        return true
      }
    } catch (e) {
      console.error(e)
      return false
    }
  }
  return false
}
