export enum ERouterPage {
  root = '/',
  login = '/login',
  loginSetup = '/login-setup',
  loginVerify = '/login-verify',
  dashboard = '/dashboard',
  inbox = '/inbox',
  clientManager = '/client-manager',
  docsAndReports = '/docs-and-reports',
  payables = '/payables',
  receivables = '/receivables',
  purchasers = '/purchasers',
  payments = '/payments',
  receipts = '/receipts',
  catalog = '/catalog',
  vendors = '/vendors',
  purchaseOrders = '/purchase-orders',
  workOrders = '/work-orders',
  error = '/error',
  verifyEmail = '/verify-email',
  acceptTos = '/accept-terms-of-service',
  reset = '/reset',
  forgotPassword = '/forgot-password',
  syncStatus = '/sync-status',
  termsOfService = '/terms-of-service',
  deferralTermsAndConditions = '/deferral-terms-and-conditions',
  referralTermsAndConditions = '/referral-terms-and-conditions',
  unsupported = '/unsupported',
  publicPurchaseOrder = '/public-purchase-order',
  matching = '/matching',
  inventoryTransfers = '/inventory-transfers',
  salesOrders = '/sales-orders',
  locations = '/locations',
  renewSubscription = '/renew-subscription',
  inventory = '/inventory',
  submitW9 = '/submit-w9',
}

export enum EBusinessFormAnchor {
  w9 = 'business-w9',
  logoUpload = 'logo-upload',
  businessName = 'business-name',
  stateOfFormation = 'state-of-formation',
  businessStructure = 'business-structure',
  businessStructureTaxClassification = 'business-structure-tax-classification',
  doingBizAs = 'doing-biz-as',
  einTin = 'business-details-form_ein-tin_input',
  website = 'website',
  country = 'country',
  industry = 'industry',
  address = 'address',
  mailingAddress = 'mailingAddress',
}

export type TVendorOnboardingPage =
  | 'business-details'
  | 'upload-w9'
  | 'link-bank-accounts'
  | 'verify-vendor-international'
  | 'verification-deposits'
  | 'verification-failed'
  | 'verify-vendor-plaid'
  | 'verify-vendor-manually'
  | 'complete-verification'

export const GUIDED_BILL_EXPERIENCE_QUERY_PARAM = 'guided_bill_exp'
export const GUIDED_PO_EXPERIENCE_QUERY_PARAM = 'guided_po_exp'
export const FOCUSED_LINE_ITEM_ID_QUERY_PARAM = 'focused_line_item_id'

export const queryActions = {
  approveDebit: 'approve_debit',
  extend: 'extend',
  approve: 'approve',
  disapprove: 'disapprove',
  approveInvoice: 'approve_invoice',
  rejectInvoice: 'reject_invoice',
  factor: 'factor',
  /**
   * Opens the comment section for a specified document.
   */
  docComment: 'doc_comment',
  /**
   * Triggers the request to authorize the sync with Finaloop.
   */
  requestFinancialsSync: 'request_financials_sync',
} as const

export const PAYMENT_NAVIGATION_QUERY_PARAMS = [
  'transfer_id',
  'reconciliation_item_id',
  'ext_period',
  'offer_id',
  'payment_dialog',
  'auto_focus',
] as const
