import { forwardRef } from 'react'
import { styled, type Interpolation, type Theme } from '@mui/material'
import type { StyledOptions } from '@emotion/styled'
import compact from 'lodash/compact'
import { getSelectorClassName } from './helpers/getSelectorClassName/getSelectorClassName'

export const styledWithSelector = <
  Element extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<React.ComponentProps<Element>>,
  Props extends Element extends keyof JSX.IntrinsicElements
    ? JSX.IntrinsicElements[Element]
    : React.ComponentProps<Element>,
>(
  Element: Element,
  options?: StyledOptions<Props>,
) => {
  const selectorClassName = getSelectorClassName()

  const styledFactory = styled(
    // eslint-disable-next-line react/display-name
    forwardRef<React.ElementRef<Element>, Props & { className?: Maybe<string> }>(({ className, ...props }, ref) => (
      // @ts-expect-error: ignorable "Union type that is too complex to represent"
      <Element {...props} ref={ref} className={compact([className, selectorClassName]).join(' ')} />
    )),
    options,
  )

  return <StyledProps extends object>(params: Interpolation<Props & StyledProps & { theme: Theme }>) =>
    Object.assign(styledFactory(params), {
      [Symbol.toPrimitive](hint: string) {
        switch (hint) {
          case 'string':
            return `.${selectorClassName}`
          case 'number':
            return NaN
          default:
            return true
        }
      },
    })
}
