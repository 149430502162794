/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const ButtonBaseSizeDesktopSmallHeight = '32px'
export const ButtonBaseSizeDesktopSmallMinWidth = '96px'
export const ButtonBaseSizeDesktopMediumHeight = '40px'
export const ButtonBaseSizeDesktopMediumMinWidth = '96px'
export const ButtonBaseSizeDesktopLargeHeight = '48px'
export const ButtonBaseSizeDesktopLargeMinWidth = '96px'
export const ButtonBaseSizeMobileSmallHeight = '32px'
export const ButtonBaseSizeMobileSmallMinWidth = '96px'
export const ButtonBaseSizeMobileMediumHeight = '40px'
export const ButtonBaseSizeMobileMediumMinWidth = '96px'
export const ButtonBaseSizeMobileLargeHeight = '48px'
export const ButtonBaseSizeMobileLargeMinWidth = '96px'
export const ButtonBaseBorderRadiusDesktopLarge = '6px'
export const ButtonBaseBorderRadiusDesktopMedium = '5px'
export const ButtonBaseBorderRadiusDesktopSmall = '4px'
export const ButtonBaseBorderRadiusMobileLarge = '6px'
export const ButtonBaseBorderRadiusMobileMedium = '5px'
export const ButtonBaseBorderRadiusMobileSmall = '4px'
export const ButtonBaseSpacingIconOffDesktopSmallLeft = '8px'
export const ButtonBaseSpacingIconOffDesktopSmallRight = '8px'
export const ButtonBaseSpacingIconOffDesktopMediumLeft = '12px'
export const ButtonBaseSpacingIconOffDesktopMediumRight = '12px'
export const ButtonBaseSpacingIconOffDesktopLargeLeft = '16px'
export const ButtonBaseSpacingIconOffDesktopLargeRight = '16px'
export const ButtonBaseSpacingIconOffMobileSmallLeft = '8px'
export const ButtonBaseSpacingIconOffMobileSmallRight = '8px'
export const ButtonBaseSpacingIconOffMobileMediumLeft = '12px'
export const ButtonBaseSpacingIconOffMobileMediumRight = '12px'
export const ButtonBaseSpacingIconOffMobileLargeLeft = '16px'
export const ButtonBaseSpacingIconOffMobileLargeRight = '16px'
export const ButtonBaseSpacingIconLDesktopSmallLeft = '4px'
export const ButtonBaseSpacingIconLDesktopSmallRight = '8px'
export const ButtonBaseSpacingIconLDesktopSmallGap = '4px'
export const ButtonBaseSpacingIconLDesktopMediumLeft = '8px'
export const ButtonBaseSpacingIconLDesktopMediumRight = '12px'
export const ButtonBaseSpacingIconLDesktopMediumGap = '8px'
export const ButtonBaseSpacingIconLDesktopLargeLeft = '12px'
export const ButtonBaseSpacingIconLDesktopLargeRight = '16px'
export const ButtonBaseSpacingIconLDesktopLargeGap = '12px'
export const ButtonBaseSpacingIconLMobileSmallLeft = '4px'
export const ButtonBaseSpacingIconLMobileSmallRight = '8px'
export const ButtonBaseSpacingIconLMobileSmallGap = '4px'
export const ButtonBaseSpacingIconLMobileMediumLeft = '8px'
export const ButtonBaseSpacingIconLMobileMediumRight = '12px'
export const ButtonBaseSpacingIconLMobileMediumGap = '8px'
export const ButtonBaseSpacingIconLMobileLargeLeft = '12px'
export const ButtonBaseSpacingIconLMobileLargeRight = '16px'
export const ButtonBaseSpacingIconLMobileLargeGap = '12px'
export const ButtonBaseSpacingIconRDesktopSmallLeft = '8px'
export const ButtonBaseSpacingIconRDesktopSmallRight = '4px'
export const ButtonBaseSpacingIconRDesktopSmallGap = '4px'
export const ButtonBaseSpacingIconRDesktopMediumLeft = '12px'
export const ButtonBaseSpacingIconRDesktopMediumRight = '8px'
export const ButtonBaseSpacingIconRDesktopMediumGap = '8px'
export const ButtonBaseSpacingIconRDesktopLargeLeft = '16px'
export const ButtonBaseSpacingIconRDesktopLargeRight = '12px'
export const ButtonBaseSpacingIconRDesktopLargeGap = '12px'
export const ButtonBaseSpacingIconRMobileSmallLeft = '8px'
export const ButtonBaseSpacingIconRMobileSmallRight = '4px'
export const ButtonBaseSpacingIconRMobileSmallGap = '4px'
export const ButtonBaseSpacingIconRMobileMediumLeft = '12px'
export const ButtonBaseSpacingIconRMobileMediumRight = '8px'
export const ButtonBaseSpacingIconRMobileMediumGap = '8px'
export const ButtonBaseSpacingIconRMobileLargeLeft = '16px'
export const ButtonBaseSpacingIconRMobileLargeRight = '12px'
export const ButtonBaseSpacingIconRMobileLargeGap = '12px'
