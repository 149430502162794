import { forwardRef } from 'react'
import { CircularProgress } from '@mui/material'
import * as $ from './IconButton.styled'
import type { IIconButtonProps } from './IconButton.types'

export type { TIconButton } from './IconButton.types'

export const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>(
  ({ size = 'medium', kind = 'neutral', variant = 'tertiary', sx, loading, children, ...otherProps }, ref) => (
    <$.IconButton sx={sx} kind={kind} variant={variant} size={size} ref={ref} {...otherProps}>
      {loading ? <CircularProgress size={20} color="inherit" /> : children}
    </$.IconButton>
  ),
)

IconButton.displayName = 'IconButton'
