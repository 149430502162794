import { Router } from 'next/router'
import { useEffect } from 'react'
import { Progress } from './components/Progress/Progress'
import { useAppProgressStore } from './hooks/useAppProgressStore/useAppProgressStore'

export const AppProgress: React.FC = () => {
  const key = useAppProgressStore((state) => state.key)
  const end = useAppProgressStore((state) => state.end)
  const start = useAppProgressStore((state) => state.start)

  useEffect(() => {
    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)

    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
    }
  }, [end, start])

  return <Progress key={key} />
}
