/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const IconButtonSecondaryBorderWidthDefault = '1px'
export const IconButtonSecondaryBorderWidthHovered = '1px'
export const IconButtonSecondaryBorderWidthPressed = '1px'
export const IconButtonSecondaryBorderWidthDisabled = '1px'
export const IconButtonSecondaryColorPositiveDefaultBackground = '#ffffff00'
export const IconButtonSecondaryColorPositiveDefaultOnBackground = '#00000099'
export const IconButtonSecondaryColorPositiveDefaultOutline = '#0000001f'
export const IconButtonSecondaryColorPositiveHoveredBackground = '#00000014'
export const IconButtonSecondaryColorPositiveHoveredOnBackground = '#00000099'
export const IconButtonSecondaryColorPositiveHoveredOutline = '#0000001f'
export const IconButtonSecondaryColorPositivePressedBackground = '#00000029'
export const IconButtonSecondaryColorPositivePressedOnBackground = '#00000099'
export const IconButtonSecondaryColorPositivePressedOutline = '#0000001f'
export const IconButtonSecondaryColorPositiveDisabledBackground = '#ffffff00'
export const IconButtonSecondaryColorPositiveDisabledOnBackground = '#00000061'
export const IconButtonSecondaryColorPositiveDisabledOutline = '#0000001f'
export const IconButtonSecondaryColorDestructiveDefaultBackground = '#ffffff00'
export const IconButtonSecondaryColorDestructiveDefaultOnBackground = '#f4511e'
export const IconButtonSecondaryColorDestructiveDefaultOutline = '#0000001f'
export const IconButtonSecondaryColorDestructiveHoveredBackground = '#f4511e14'
export const IconButtonSecondaryColorDestructiveHoveredOnBackground = '#f4511e'
export const IconButtonSecondaryColorDestructiveHoveredOutline = '#0000001f'
export const IconButtonSecondaryColorDestructivePressedBackground = '#f4511e29'
export const IconButtonSecondaryColorDestructivePressedOnBackground = '#f4511e'
export const IconButtonSecondaryColorDestructivePressedOutline = '#0000001f'
export const IconButtonSecondaryColorDestructiveDisabledBackground = '#ffffff00'
export const IconButtonSecondaryColorDestructiveDisabledOnBackground = '#00000061'
export const IconButtonSecondaryColorDestructiveDisabledOutline = '#0000001f'
export const IconButtonSecondaryColorWarningDefaultBackground = '#ffffff00'
export const IconButtonSecondaryColorWarningDefaultOnBackground = '#fb8c00'
export const IconButtonSecondaryColorWarningDefaultOutline = '#0000001f'
export const IconButtonSecondaryColorWarningHoveredBackground = '#fb8c0014'
export const IconButtonSecondaryColorWarningHoveredOnBackground = '#fb8c00'
export const IconButtonSecondaryColorWarningHoveredOutline = '#0000001f'
export const IconButtonSecondaryColorWarningPressedBackground = '#fb8c0029'
export const IconButtonSecondaryColorWarningPressedOnBackground = '#fb8c00'
export const IconButtonSecondaryColorWarningPressedOutline = '#0000001f'
export const IconButtonSecondaryColorWarningDisabledBackground = '#ffffff00'
export const IconButtonSecondaryColorWarningDisabledOnBackground = '#00000061'
export const IconButtonSecondaryColorWarningDisabledOutline = '#0000001f'
