import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { identifyUser, trackPage } from 'lib/tracking/segment'
import { useUserTraits } from '../../../../hooks/useUserTraits/useUserTraits'

export const useSegmentIdentityTracking = (profileId: string) => {
  const router = useRouter()
  const traits = useUserTraits()

  // initial identifyUser(profileId) call to let segment identify the user via the profileId
  // it should be called prior to any other events
  useEffect(() => {
    if (profileId) {
      identifyUser(profileId, traits ?? {})
    }
  }, [profileId, traits])

  const handleRouteChangeEvent = useCallback(() => trackPage(), [])

  // trackPage should be called on mount after identifyUser(profileId) is called
  useEffect(() => {
    handleRouteChangeEvent()
    router.events.on('routeChangeComplete', handleRouteChangeEvent)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeEvent)
    }
  }, [])
}
