import { FC } from 'react'
import { useDatadogIdentityTracking } from './hooks/useDatadogIdentityTracking'

interface IDatadogIdentityTrackingProps {
  profileId: string
}
// DatadogIdentityTracking component is a wrapper around useDatadogIdentityTracking, so it can be used inside App.tsx
// Component should be rendered inside ApolloProvider
export const DatadogIdentityTracking: FC<IDatadogIdentityTrackingProps> = ({ profileId }) => {
  useDatadogIdentityTracking(profileId)
  return null
}
