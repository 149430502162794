import type { Router } from 'next/router'
import { getOrderedParamKeys } from '../getOrderedParamKeys/getOrderedParamKeys'

export const getRecursiveDialogParamAsLocation = (asPath: Router['asPath']) => {
  const recursiveDialogParamKeys = getOrderedParamKeys(asPath, [
    'invoice_id',
    'receipt_id',
    'receivable_id',
    'purchase_order_id',
  ])

  const topLevelParam = recursiveDialogParamKeys[recursiveDialogParamKeys.length - 1]

  if (topLevelParam === 'invoice_id') {
    return 'bill-details-dialog' as const
  }

  if (topLevelParam === 'receivable_id') {
    return 'invoice-details-dialog' as const
  }

  if (topLevelParam === 'receipt_id') {
    return 'shipping-receipt-details-dialog' as const
  }

  if (topLevelParam === 'purchase_order_id') {
    return 'purchase-order-details-dialog' as const
  }

  return null
}
