import { z } from 'zod'

/**
 * Parses the given value and returns the corresponding enum value if it exists in the enumValues array.
 * If the value does not exist in the enumValues array, it returns the default value specified in the config object.
 *
 * @template T - The type of the enum values.
 * @param {[T, ...T[]]} enumValues - An array of enum values.
 * @param {unknown} value - The value to parse.
 * @returns {T | null} - The parsed enum value or null.
 */
export const enumParse = <T extends string>(enumValues: [T, ...T[]], value: unknown): T | null => {
  const result = z.enum(enumValues).safeParse(value)

  return result.success ? result.data : null
}
