import { useEffect } from 'react'
import { setCookie, deleteCookie } from 'cookies-next'

const COOKIE_NAME = 'profile_id'

export const useSetProfileIdCookie = (profileId: Maybe<string>) => {
  useEffect(() => {
    if (profileId) {
      setCookie(COOKIE_NAME, profileId)
    } else {
      deleteCookie(COOKIE_NAME)
    }
  }, [profileId])
}
