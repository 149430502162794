import type { Router } from 'next/router'
import { getSettingsQueryAsLocation } from './helpers/getSettingsQueryAsLocation/getSettingsQueryAsLocation'
import { isPaymentDialogInQuery } from './helpers/isPaymentDialogInQuery/isPaymentDialogInQuery'
import { getRecursiveDialogParamAsLocation } from './helpers/getRecursiveDialogParamAsLocation/getRecursiveDialogParamAsLocation'
import { getPathnameAsLocation } from './helpers/getPathnameAsLocation/getPathnameAsLocation'

export type TGetSnackbarLocationProps = Pick<Router, 'pathname' | 'query' | 'asPath'>

export const getSnackbarLocation = ({ pathname, query, asPath }: TGetSnackbarLocationProps): string | null => {
  if ('settings' in query) {
    return getSettingsQueryAsLocation(query)
  }

  if ('paymentWizard' in query) {
    return 'add-payment-wizard'
  }

  if (isPaymentDialogInQuery(query)) {
    return 'payment-details-dialog'
  }

  const recursiveDialogParamAsLocation = getRecursiveDialogParamAsLocation(asPath)

  if (recursiveDialogParamAsLocation) {
    return recursiveDialogParamAsLocation
  }

  return getPathnameAsLocation(pathname)
}
