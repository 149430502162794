import { useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import camelCase from 'lodash/camelCase'

type TLDFlagSet = ReturnType<typeof useFlags>

export const useGlobalLDFlagsGetter = () => {
  const flags = useFlags()

  useEffect(() => {
    // works with the following arguments:
    // - <no arguments> - returns all flags
    // - true - returns only truthy flags
    // - false - returns only falsy flags
    // - 'flagKey' - returns only flags that match the key
    // - 'flag-key' - returns only flags that match the key (kebab-case)
    // - 'flagKey', 'flag-key-2' - returns only flags that match any of the keys
    // - 'flagKey', 'flag-key-3', <boolean> - returns only flags that match any of the keys and any of the booleans
    window.getLDFlags = (...searchedFlags: (keyof TLDFlagSet | boolean)[]): Partial<TLDFlagSet> => {
      if (!searchedFlags.length) {
        return { ...flags }
      }

      return Object.fromEntries(
        Object.entries(flags || {}).filter(([key, value]) =>
          searchedFlags.some((search) =>
            typeof search === 'boolean' ? value === search : search === key || camelCase(search.toString()) === key,
          ),
        ),
      )
    }
  }, [flags])
}
