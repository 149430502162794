import { isMatch, merge } from 'lodash'
import { useEffect, useRef } from 'react'
import { NormalizedCacheObject } from '@apollo/client'
import { initializeApollo, setRequestLinkBusinessId } from 'lib/apollo-client'

interface IUseInitializedApolloClientOptions {
  businessId: string | null
  initialCache: NormalizedCacheObject | undefined
}

const initialApolloClient = initializeApollo()

export const useInitializedApolloClient = ({ businessId, initialCache }: IUseInitializedApolloClientOptions) => {
  setRequestLinkBusinessId(businessId)
  const businessRef = useRef(businessId)
  const apolloClientRef = useRef(initialApolloClient)

  if (initialCache) {
    const currentCache = apolloClientRef.current.cache.extract()
    const isCacheMatch = isMatch(currentCache, initialCache)

    if (!isCacheMatch) {
      merge(currentCache, initialCache)
      apolloClientRef.current.cache.restore(currentCache)
    }
  }

  useEffect(() => {
    if (businessRef.current !== businessId) {
      businessRef.current = businessId
      apolloClientRef.current = initializeApollo({ businessId })
    }
  }, [businessId])

  return apolloClientRef.current
}
