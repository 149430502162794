/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const SnackbarSizeDesktop = '48px'
export const SnackbarSizeMobile = '48px'
export const SnackbarBorderRadiusDesktop = '5px'
export const SnackbarBorderRadiusMobile = '5px'
export const SnackbarSpacingMediumDesktopHorizontal = '24px'
export const SnackbarSpacingMediumDesktopVertical = '8px'
export const SnackbarSpacingMediumDesktopGap = '16px'
export const SnackbarSpacingMediumMobileHorizontal = '24px'
export const SnackbarSpacingMediumMobileVertical = '8px'
export const SnackbarSpacingMediumMobileGap = '16px'
export const SnackbarBorderWidthAlert = '0px'
export const SnackbarBorderWidthError = '0px'
export const SnackbarBorderWidthSuccess = '0px'
export const SnackbarColorWarningBackground = '#fb8c00'
export const SnackbarColorWarningOnBackground = '#ffffff'
export const SnackbarColorWarningOutline = '#ffffff00'
export const SnackbarColorErrorBackground = '#f4511e'
export const SnackbarColorErrorOnBackground = '#ffffff'
export const SnackbarColorErrorOutline = '#ffffff00'
export const SnackbarColorSuccessBackground = '#7cb342'
export const SnackbarColorSuccessOnBackground = '#ffffff'
export const SnackbarColorSuccessOutline = '#ffffff00'
export const SnackbarColorInfoBackground = '#546e7a'
export const SnackbarColorInfoOnBackground = '#ffffff'
export const SnackbarColorInfoOutline = '#ffffff00'
export const SnackbarShadowDesktopX = '0px'
export const SnackbarShadowDesktopY = '0px'
export const SnackbarShadowDesktopBlur = '24px'
export const SnackbarShadowDesktopSpread = '0px'
export const SnackbarShadowDesktopColor = '#00000026'
export const SnackbarShadowDesktopType = 'dropShadow'
export const SnackbarShadowMobileX = '0px'
export const SnackbarShadowMobileY = '0px'
export const SnackbarShadowMobileBlur = '24px'
export const SnackbarShadowMobileSpread = '0px'
export const SnackbarShadowMobileColor = '#00000026'
export const SnackbarShadowMobileType = 'dropShadow'
