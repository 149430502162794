import React, { useCallback, useEffect, useState, createContext } from 'react'
import noop from 'lodash/noop'
import { useRouter } from 'next/router'
import { getLSItem } from 'lib/helpers/getLSItem/getLSItem'
import { VENDORS_WITH_INVOICES_FILTER_PARAM } from 'lib/navigation/hooks/useVendorsNavigation/useVendorsNavigation.consts'

const SHOW_FEES_STORAGE_KEY = 'showFees'
const SHOW_VENDORS_WITH_INVOICES_TOGGLE_KEY = 'showVendorsWithInvoices'

export interface IUserSettings {
  showFees: boolean
  showVendorsWithInvoices: boolean
}

export interface IUserSettingsActions {
  setShowFees(value: boolean): void
  setShowVendorsWithInvoices(value: boolean): void
}

export const UserSettingsContext = createContext<IUserSettings & IUserSettingsActions>({
  showFees: true,
  setShowFees: noop,
  showVendorsWithInvoices: false,
  setShowVendorsWithInvoices: noop,
})

const getShowFeesSavedValue = () => {
  if (getLSItem(SHOW_FEES_STORAGE_KEY) === 'false') {
    return false
  }
  return true
}

const getShowVendorsWithInvoicesSavedValue = () => {
  if (getLSItem(SHOW_VENDORS_WITH_INVOICES_TOGGLE_KEY) === 'true') {
    return true
  }
  return false
}

export const UserSettingsProvider: Settle.FC = ({ children }) => {
  const { query } = useRouter()

  const [showFeesState, setShowFeesState] = useState(getShowFeesSavedValue())
  const [showVendorsWithInvoicesState, setShowVendorsWithInvoicesState] = useState(
    getShowVendorsWithInvoicesSavedValue(),
  )

  const setShowFees = useCallback(
    (value: boolean) => {
      setShowFeesState(value)
      localStorage.setItem(SHOW_FEES_STORAGE_KEY, String(value))
    },
    [setShowFeesState],
  )

  const setShowVendorsWithInvoices = useCallback(
    (value: boolean) => {
      setShowVendorsWithInvoicesState(value)
      localStorage.setItem(SHOW_VENDORS_WITH_INVOICES_TOGGLE_KEY, String(value))
    },
    [setShowVendorsWithInvoicesState],
  )

  const initializeSettings = () => {
    if (getLSItem(SHOW_FEES_STORAGE_KEY) === 'false') {
      setShowFeesState(false)
    }
    if (getLSItem(SHOW_VENDORS_WITH_INVOICES_TOGGLE_KEY) === 'true') {
      setShowVendorsWithInvoicesState(true)
    }
  }

  useEffect(initializeSettings, [])

  const showFeesValue = 'fees' in query ? query.fees === 'true' : showFeesState
  const showVendorsWithInvoicesValue =
    VENDORS_WITH_INVOICES_FILTER_PARAM in query
      ? query[VENDORS_WITH_INVOICES_FILTER_PARAM] === 'true'
      : showVendorsWithInvoicesState

  return (
    <UserSettingsContext.Provider
      value={{
        showFees: showFeesValue,
        setShowFees,
        showVendorsWithInvoices: showVendorsWithInvoicesValue,
        setShowVendorsWithInvoices,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  )
}
