import { StyledEngineProvider } from '@mui/material'

// Fixes styled engine breaking ssr styles
const StyledEngine: Settle.FC = ({ children }) => {
  if (typeof window !== 'undefined') {
    return <StyledEngineProvider injectFirst={true}>{children}</StyledEngineProvider>
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default StyledEngine
