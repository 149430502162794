import { create } from 'zustand'

export interface IPathParamsStore {
  businessId: string
  uuid: string
  setBusinessId(businessId: string): void
  setUuid(uuid: string): void
  clear(): void
}

export const usePathParamsStore = create<IPathParamsStore>((set, get) => ({
  businessId: '',
  uuid: '',
  setBusinessId: (businessId) => {
    const currentState = get()

    if (currentState.businessId === businessId) {
      return
    }

    set({ businessId })
  },
  setUuid: (uuid) => {
    const currentState = get()

    if (currentState.uuid === uuid) {
      return
    }

    set({ uuid })
  },
  clear: () =>
    set(
      {
        businessId: '',
        uuid: '',
      },
      true,
    ),
}))
