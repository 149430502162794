import last from 'lodash/last'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { getCookie, setCookie } from 'cookies-next'
import { qsStringSchema } from 'lib/zod'

/** @link: https://developers.facebook.com/docs/marketing-api/conversions-api/parameters */
export const useSetFacebookFbcCookie = () => {
  const { query } = useRouter()

  const fbclid = qsStringSchema.parse(query.fbclid)
  const fbcCookie = qsStringSchema.parse(getCookie('_fbc'))
  const isCookieUpToDate = last(fbcCookie.split('.')) === fbclid

  useEffect(() => {
    if (fbclid && !isCookieUpToDate) {
      const version = 'fb'
      const subdomainIndex = 0 // *.com level
      const creationTime = Date.now()
      const maxAge = 60 * 60 * 24 * 90 // 90 days

      setCookie('_fbc', `${version}.${subdomainIndex}.${creationTime}.${fbclid}`, { maxAge })
    }
  }, [isCookieUpToDate, fbclid])
}
