import { useMemo } from 'react'
import { useBusinessIdPathParam } from 'lib/navigation/hooks/useBusinessIdPathParam/useBusinessIdPathParam'
import { getBusinessTraits } from 'lib/helpers/getBusinessTraits/getBusinessTraits'
import { useBusinessTraitsHook_businesses } from './useBusinessTraits.graphql'

export const useBusinessTraits = () => {
  const businessId = useBusinessIdPathParam()
  const query = useBusinessTraitsHook_businesses({
    fetchPolicy: 'cache-and-network',
    variables: { businessId },
    skip: !businessId,
  })
  const [business] = query.data?.businesses ?? []

  return useMemo(() => (business ? getBusinessTraits(business) : null), [business])
}
